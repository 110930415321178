import React from 'react'

import Layout from 'components/Layout'
import Footer from 'components/Footer'
import { mailtoContact } from 'components/Header/constants'

import {
  TeamsMap,
  UsaPin,
  UkPin,
  FrancePin,
  NetherlandsPin,
  IndiaPin,
} from 'components/Icons'

const Team = () => (
  <Layout light>
    <div className="team__container">
      <div className="container light">
        <section className="team">
          <h1 className="team__heading">
            Skill Specific,
            <span>{' Border Agnostic.'}</span>
          </h1>
          <div className="team__map">
            <TeamsMap />
            <div className="location-pin --us" data-sal="slide-down">
              <UsaPin />
            </div>
            <div className="location-pin --uk" data-sal="slide-down">
              <UkPin />
            </div>
            <div className="location-pin --france" data-sal="slide-down">
              <FrancePin />
            </div>
            <div className="location-pin --netherlands" data-sal="slide-down">
              <NetherlandsPin />
            </div>
            <div className="location-pin --india" data-sal="slide-down">
              <IndiaPin />
            </div>
          </div>
          <div className="team__careers">
            <h1 className="team__sub-heading">
              Interested in working with us?
            </h1>
            <a
              className="team__careers-link"
              href={mailtoContact}
              target="_blank"
              rel="noopener noreferrer"
            >
              Careers
            </a>
          </div>
        </section>
      </div>
    </div>
    <Footer />
  </Layout>
)

export default Team
